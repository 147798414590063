<template>
  <v-content>
    <div class="page">
      <AppBar :drawer="false"/>
      <v-container class="text-center">
        <v-row align="center" justify="center">
          <v-col cols="12" class="pt-0">
            <v-badge
              offset-x="40"
              offset-y="20"
              overlap
              bottom
              color="#70445E"
              class="custom"
            >
            <template v-slot:badge>
              <v-icon x-large color="#70445E" v-if="!isPlaying" @click="play">mdi-play-circle</v-icon>
              <v-icon x-large color="#70445E" v-else @click="pause">mdi-pause-circle</v-icon>
            </template>
              <v-avatar rounded size="200">
                <v-img
                  class="mx-auto text-center"
                  max-width="145"
                  max-height="300"
                  aspect-ratio=".7"
                  :src="require('../assets/img/hojenaopodebrincarlafora.png')"
                ></v-img>
              </v-avatar>
            </v-badge>
          </v-col>
          <v-col cols="12" class="pt-0">
            <h1 class="text-h5 laubeFont text-center">
              Brincadeira de Criança
            </h1>
          </v-col>
          <v-col cols="12" class="pt-0" style="margin-top: -2px;">
            <audio id="audio" controls controlsList="nodownload">
              <source :src="file" type="audio/mpeg">
            Desculpe infelizmente o seu navegador não tem audio liberado, peça o download da música.
            </audio>
            <!-- <av-waveform
              :audio-src="file"
              canv-top
              :canv-width="200"
              noplayed-line-color="#C5D0D8"
              played-line-color="#D31809"
              :playtime-with-ms="false"
              playtime-font-family="Laube"
              playtime-font-color="#ffffff"
              playtime-slider-color="#D31809"
            ></av-waveform> -->
          </v-col>
          <v-col cols="12" class="pt-0">
            <v-card
              elevation="1"
              class="mt-3 mx-auto overflow-y-auto"
              width="450"
              max-height="310"
            >
              <v-toolbar dense flat class="toolbar">
                <v-app-bar-title style="background: none;">LETRA</v-app-bar-title>
              </v-toolbar>
              <v-card-text class="text-left text-body-1 pt-3 cardText">
                Aqui do chão,<br>
                o mundo é sem limites<br><br>

                Multicolorido como bolhas de sabão,<br>
                espetacular como lava de um vulcão<br><br>


                Panela vira bumbo<br>
                Gargalhada,<br>
                canto lírico<br><br>

                ah ha ha ha ha<br><br>

                Faz chocalho com feijões<br>
                e chacoalho emoções<br><br>


                Em um mergulho profundo,<br>
                a imaginação com que respiro,<br>
                me transforma em qualquer bicho<br><br>

                Daqui do chão do brincar<br>
                somos super heróis<br>
                sempre prestes a voar<br><br>

                Não é preciso capa<br>
                salvo o mundo batendo palmas<br><br>

                Aqui do chão gente pequena ensina<br>
                gente grande,<br>
                que levar a vida na brincadeira...<br><br>

                É não ter limites<br>
                para ir adiante<br>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" class="py-0">
            <v-img :src="require('../assets/img/partitura.png')" contain :aspect-ratio="3/4" :height="700"></v-img>
          </v-col>
        </v-row>
        <v-row align="start" justify="end">
          <v-col cols="3" class="text-right" style="cursor: pointer;" @click="goBack()">
            <v-btn fab plain class="my-0 py-0">
              <v-img :src="require('../assets/img/laubeVoltar.png')" width="75"></v-img>
            </v-btn>
            <p class="my-0 py-0" style="color: #70445E;">Voltar</p>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-content>
</template>

<script>
import AppBar from '../components/AppBar.vue'
export default {
  name: 'BrincadeiradeCrianca',
  components: {
    AppBar
  },
  data () {
    return {
      isPlaying: false,
      file: require("../assets/musicas/brincadeiradecrianca.mp3"),
    }
  },
  methods: {
    play () {
      this.isPlaying = true
      var audio = document.getElementById('audio');
      audio.play()
    },
    pause () {
      this.isPlaying = false
      var audio = document.getElementById('audio');
      audio.pause()
    },
    goBack () {
      window.location.href = './livroHojeNaoPodeBrincarLaFora'
    }
  },
}
</script>
<style scoped>
  @font-face {
      font-family: Laube;
      src: url('../assets/fonts/Blue Vinyl Fonts - BlueVinyl-Bold.otf');
  }
  @font-face {
      font-family: Letra;
      src: url('../assets/fonts/OpenSans-VariableFont_wdth,wght.ttf');
  }

  * {
      background-color: #d4c1ba;
    }

  .laubeFont {
    font-family: Laube !important;
    color: #70445E !important;
    font-weight: lighter !important;
  }

  ::-webkit-scrollbar{
    width: 7px;
    height: 7px;
  }
  ::-webkit-scrollbar-thumb{
    background: #70445E;
    border-radius: 0px;
  }
  ::-webkit-scrollbar-thumb:hover{
    background: #392B2A;
  }
  ::-webkit-scrollbar-track{
    background: #70445E;
    border-radius: 0px;
    box-shadow: inset 0px 0px 0px 0px #F0F0F0;
  }
  ::-webkit-media-controls-panel {
    background-color: #C5D0D8;
  }
  ::-webkit-media-controls-timeline {
    background-color: #C5D0D8;
    border-radius: 25px;
    padding: 0 0;
    margin: 0 10px;
  }
  .toolbar {
    z-index: 1 !important;
    font-family: Letra !important;
    color: #C5D0D8 !important;
    background-color: #70445E !important;
  }
  .cardText {
    font-family: Letra !important;
    font-weight: 500;
    color: #70445E;
    background-color: #C5D0D8;
  }
</style>
